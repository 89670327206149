import React from 'react';
import { Link } from 'react-router-dom';
import './Landing.css';

//Import Components
import logo from '../../SUPONLINE.png';

function Landing() {
    return (
        <div className='main-container'>
            <div className='main-header-cont'>
                <Link to='/' className='sup-logo'>
                    <img src={logo} alt='National Society of the Sons of Utah Pioneers' />
                </Link>
                <div className='contact-info-cont'>
                    <h3>For further information on library books and memorialized pioneers, please contact the SUP National Library:</h3>
                    <br />
                    <div className="contact-info">
                        <p><b>Phone:</b> 801-484-4441</p>
                        <p><b>Address:</b> 3301 E. Louise Ave Millcreek, UT 84109</p>
                        <p><b>Email:</b> suplibrary01@gmail.com</p>
                    </div>
                </div>
            </div>
            <div className='home-heads-cont'>
                <hr />
                <h1 className='home-h1'>Sons of Utah Pioneers</h1>
                <h3 className='home-h3'>National Library Search</h3>
                <hr />
            </div>
            <div className='mobile-contact-info-cont'>
                <h3>For further information on library books and memorialized pioneers, please contact the SUP National Library:</h3>
                <br />
                <div className="contact-info">
                    <p><b>Phone:</b> 801-484-4441</p>
                    <p><b>Address:</b> 3301 E. Louise Ave Millcreek, UT 84109</p>
                    <p><b>Email:</b> suplibrary01@gmail.com</p>
                </div>
            </div>
            <div className='home-search-btn-cont' alt='The National Society of the Sons of Utah Pioneers'>
                <Link to='/booksearch'>
                    <button className='home-search-btn'>Search Books</button>
                </Link>
                <Link to='/pioneersearch'>
                    <button className='home-search-btn'>Search Pioneers</button>
                </Link>
            </div>
            <div className='sup-mission-cont'>
                <h4 className='home-h4'>THE MISSION OF THE SONS OF UTAH PIONEERS</h4>
                <p>Come to know our fathers, and turn our hearts to them. Preserve the memory and heritage of the early pioneers of the Utah Territory and the western U.S. Honor present-day pioneers worldwide who exemplify these same qualities of character. Teach these same qualities to the youth who will be tomorrow’s pioneers.</p>
            </div>
            <div className='mobile-home-search-btn-cont'>
                <Link to='/booksearch'>
                    <button className='mobile-btn book-btn'>Search Books</button>
                </Link>
                <Link to='/pioneersearch'>
                    <button className='mobile-btn pioneer-btn'>Search Pioneers</button>
                </Link>
            </div>
        </div>
    );
}

export default Landing;
