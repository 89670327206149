import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Admin from '../Admin/Admin'
import { loginUser } from '../../ducks/userReducer'
import { connect } from 'react-redux'
import logo from '../../SUPONLINE.png'

import './AdminLogin.css'


const AdminLogin = (props) => {
    const [email, setEmail] = useState('')
    // const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    useEffect(() => {
        axios.get('/api/auth/user').then(res => {
            props.loginUser(res.data)
        }).catch(error => {
            console.log(error);
        })
    }, [])

    const handleLogin = () => {
        const body = {
            email: email,
            password: password
        }
        axios.post('/api/auth/login', body).then(res => {
            props.loginUser(res.data)
            props.history.push('/admin')
        }).catch(err => alert('failed to login'))
    }



    return (
        <div className="all-login-cont">
            <div className='sup-logo login-logo'>
                <img src={logo} alt='National Society of the Sons of Utah Pioneers' />
            </div>
            <div className='login-cont'>
                <input className='input-bar' placeholder='email' onChange={e => setEmail(e.target.value)} />
                <br />
                {/* <input placeholder='username'/> */}
                <br />
                <input className='input-bar' type='password' placeholder='password' onChange={e => setPassword(e.target.value)} />
                <br />
                <button className='search-btn login-btn' onClick={() => handleLogin()} >Login</button>
            </div>
        </div>
    )
}

const mapStateToProps = state => state

export default connect(mapStateToProps, { loginUser })(AdminLogin)