import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Landing from './Components/Landing/Landing';
import BookSearch from './Components/BookSearch/BookSearch';
import PioneerSearch from './Components/PioneerSearch/PioneerSearch';
import BookResults from './Components/BookResults/BookResults';
import PioneerResults from './Components/PioneerResults/PioneerResults';
import Admin from './Components/Admin/Admin'
import AdminLogin from './Components/AdminLogin/AdminLogin' 
import AdminAdd from './Components/AdminAdd/AdminAdd'

export default
    <Switch>
        <Route exact path='/' component={Landing} />
        <Route path='/booksearch' component={BookSearch} />
        <Route path='/bookresults' component={BookResults} />
        <Route path='/pioneersearch' component={PioneerSearch} />
        <Route path='/pioneerresults' component={PioneerResults} />
        <Route path='/login' component={AdminLogin} />
        <Route exact path='/admin' component={Admin}/>
        <Route path='/admin/add' component={AdminAdd}/>
    </Switch>