import React from 'react';
import '../Styles/Results.css';

function BookResults(props) {
    return (
        <div className='b-results-container'>
            <div className='results-name-cont'>{props.selectedBooks.title}</div>
            <br />
            <div className='results-text'>
                <b>Key#:</b> {props.selectedBooks.pointer}
            </div>
            <div className='results-text'>
                <b>Author:</b> {props.selectedBooks.author}
            </div>
            <div className='results-text'>
                <b>Summary:</b> {props.selectedBooks.summary}
            </div>
            <div className='results-text'>
                <b>Places:</b> {props.selectedBooks.places}
            </div>
            <div className='results-text'>
                <b>Names Listed:</b> {props.selectedBooks.names}
            </div>
        </div>
    );
}

export default BookResults;