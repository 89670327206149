import React, { useState, useEffect } from 'react'
import axios from 'axios'
import logo from '../../SUPONLINE.png'
import './AdminAdd.css'
import '../AdminResults/AdminResults.css'
import '../Admin/Admin.css';
import { Link } from 'react-router-dom'
import { loginUser, logoutUser } from '../../ducks/userReducer'
import { connect } from 'react-redux'

const AdminAdd = (props) => {
    const [addBook, setAddBook] = useState(false)
    const [addPioneer, setAddPioneer] = useState(false)
    const [addAdmin, setAddAdmin] = useState(false)
    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [bookPointer, setBookPointer] = useState('')
    const [title, setTitle] = useState('')
    const [author, setAuthor] = useState('')
    const [names, setNames] = useState('')
    const [summary, setSummary] = useState('')
    const [places, setPlaces] = useState('')
    const [pioneerPointer, setPioneerPointer] = useState('')
    const [pioneer, setPioneer] = useState('')
    const [lifespan, setLifespan] = useState('')
    const [birthdate, setBirthdate] = useState('')
    const [birth_place, setBirth_place] = useState('')
    const [deathdate, setDeathdate] = useState('')
    const [death_place, setDeath_place] = useState('')

    useEffect(() => {
        axios.get('/api/auth/user').then(res => {
            props.loginUser(res.data)
        }).catch(error => {
            console.log(error);
        })
    }, [])

    const newAdmin = () => {
        let body = {
            email: email,
            username: username,
            password: password
        }
        if (!body.email || !body.username || !body.password) {
            alert('All Fields are Required')
        } else {

            axios.post(`/api/auth/register`, body).then(res => {
                alert('New Admin Registered')
                setAddAdmin(false)
            }).catch(err => console.log(err))
        }
    }

    const newBook = () => {
        let body = {
            pointer: bookPointer,
	    author: author,
            title: title,
            names: names,
            summary: summary,
            places: places
        }
        if (!body.pointer) {
            alert('Key # is Required')
        } else {

            axios.post(`/api/admin/books/new`, body).then(res => {
                alert('New Book Added To Database')
                setAddBook(false)
                setBookPointer('')
            }).catch(err => console.log(err))
        }
    }

    const newPioneer = () => {
        let body = {
            pointer: pioneerPointer,
            pioneer: pioneer,
            lifespan: lifespan,
            birthdate: birthdate,
            birth_place: birth_place,
            deathdate: deathdate,
            death_place: death_place
        }

        if (!body.pointer) {
            alert('Key # is Required')
        } else {

            axios.post(`/api/admin/pioneers/new`, body).then(res => {
                alert('New Pioneer Added To Database')
                setAddPioneer(false)
                setPioneerPointer('')
            }).catch(err => console.log(err))
        }
    }

    const toggleAdmin = () => {
        setAddAdmin(true)
        setAddPioneer(false)
        setAddBook(false)
    }
    const toggleBook = () => {
        setAddBook(true)
        setAddAdmin(false)
        setAddPioneer(false)
    }
    const togglePioneer = () => {
        setAddPioneer(true)
        setAddAdmin(false)
        setAddBook(false)
    }

    const handleLogout = () => {
        axios.delete('/api/auth/logout').then(() => {
            props.logoutUser()
            props.history.push('/login')
        })
    }

    return (
        <div>
            {props.isLoggedIn ?
                <div className='adminAdd-container'>
                    <div className="search-header-bg-img-cont">
                        <div className='search-header-container admin-head-cont'>
                            <Link to='/admin' className='sup-logo admin-logo adminAdd-logo'>
                                <img src={logo} alt='National Society of the Sons of Utah Pioneers' />
                            </Link>

                            <div className='search-h1'><h1>Select Below</h1></div>

                            <div className='logout-cont'>
                                <button className='logout-btn' onClick={() => handleLogout()} >Logout</button>
                            </div>
                        </div>
                    </div>
                    <div className='adminAdd-buttons-cont'>
                        <div className='new-item-cont'>
                            {addAdmin ?
                                <div className='new-item'>
                                    <b className='new-item-head'>New Admin</b>
                                    <br />
                                    <input className='input-box' placeholder='email' onChange={(e) => setEmail(e.target.value)} />
                                    <input className='input-box' placeholder='username' onChange={(e) => setUsername(e.target.value)} />
                                    <input className='input-box' placeholder='password' onChange={(e) => setPassword(e.target.value)} />
                                    <button className='save-btn' onClick={() => newAdmin()}>Add</button>
                                    <button className='cancel-btn' onClick={() => setAddAdmin(false)}>Cancel</button>
                                </div>
                                :
                                <button className='admin-btn admin-add-btn' onClick={() => toggleAdmin()} >New Admin</button>
                            }
                        </div>
                        <div className='new-item-cont'>
                            {addBook ?
                                <div className='new-item'>
                                    <b className='new-item-head'>New Book</b>
                                    <br />
                                    <input className='input-box' placeholder='Key' onChange={(e) => setBookPointer(e.target.value)} />
                                    <textarea className='textarea'
                                        id='places'
                                        rows='10'
                                        cols='98' placeholder='Title' onChange={(e) => setTitle(e.target.value)} />
                                    <input className='input-box' placeholder='Author' onChange={(e) => setAuthor(e.target.value)} />
                                    <textarea className='textarea'
                                        id='places'
                                        rows='10'
                                        cols='98' placeholder='Names' onChange={(e) => setNames(e.target.value)} />
                                    <textarea className='textarea'
                                        id='places'
                                        rows='10'
                                        cols='98' placeholder='Summary' onChange={(e) => setSummary(e.target.value)} />
                                    <textarea className='textarea'
                                        id='places'
                                        rows='10'
                                        cols='98' placeholder='Places' onChange={(e) => setPlaces(e.target.value)} />
                                    <button className='save-btn' onClick={() => newBook()}>Add</button>
                                    <button className='cancel-btn' onClick={() => setAddBook(false)}>Cancel</button>
                                </div>
                                :
                                <button className='admin-btn admin-add-btn' onClick={() => toggleBook()} >New Book</button>
                            }
                        </div>

                        <div className='new-item-cont'>
                            {addPioneer ?
                                <div className='new-item'>
                                    <b className='new-item-head'>New Pioneer</b>
                                    <br />
                                    <input className='input-box' placeholder='Key' onChange={(e) => setPioneerPointer(e.target.value)} />
                                    <input className='input-box' placeholder='Pioneer' onChange={(e) => setPioneer(e.target.value)} />
                                    <input className='input-box' placeholder='Lifespan' onChange={(e) => setLifespan(e.target.value)} />
                                    <input className='input-box' placeholder='Birth Date' onChange={(e) => setBirthdate(e.target.value)} />
                                    <input className='input-box' placeholder='Birth Place' onChange={(e) => setBirth_place(e.target.value)} />
                                    <input className='input-box' placeholder='Death Date' onChange={(e) => setDeathdate(e.target.value)} />
                                    <input className='input-box' placeholder='Death Place' onChange={(e) => setDeath_place(e.target.value)} />
                                    <button className='save-btn' onClick={() => newPioneer()}>Add</button>
                                    <button className='cancel-btn' onClick={() => setAddPioneer(false)}>Cancel</button></div>
                                :
                                <button className='admin-btn admin-add-btn' onClick={() => togglePioneer()} >New Pioneer</button>
                            }
                        </div>
                    </div>



                </div>
                :
                <div>Please Log In To Continue</div>
            }
        </div>
    )
}

const mapStateToProps = state => state

export default connect(mapStateToProps, { loginUser, logoutUser })(AdminAdd)
