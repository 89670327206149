import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import '../Styles/Search.css';
import ReactPaginate from 'react-paginate'
import '../Styles/Pagination.css'
import { HashLink as HashLink } from 'react-router-hash-link';

//Import Components
import logo from '../../SUPONLINE.png';
import BookResults from '../BookResults/BookResults';

function BookSearch() {
    const [searchedBooks, setSearchedBooks] = useState([])
    const [userInput, setUserInput] = useState('')
    const [hasSearched, setHasSearched] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [booksPerPage] = useState(25)
    const [offset, setOffset] = useState(0)
    const [pageCount, setPageCount] = useState(0)

    const submitHandler = event => {
        event.preventDefault();
        bookSearch();
    }

    const bookSearch = () => {

        axios.get(`/api/books?names=${userInput}`).then(res => {
            setSearchedBooks(res.data)
            setHasSearched(true)
            setCurrentPage(0)
            setOffset(0)
        }).catch(() => alert('something went wrong'))
    }

    useEffect(() => {
        setPageCount(Math.ceil(searchedBooks.length / booksPerPage))
    }, [searchedBooks])


    const currentBooks = searchedBooks.slice(offset, offset + booksPerPage)

    const handlePageClick = (e) => {
        const selectedPage = e.selected
        const offset = selectedPage * booksPerPage

        setCurrentPage(selectedPage)
        setOffset(offset)
    }

    const mappedBooks = currentBooks.map((selectedBooks) => {
        return (
            <BookResults
                key={selectedBooks.id}
                selectedBooks={selectedBooks}
            />
        )
    })

    return (
        <div className='search-container' >
            <div className='search-header-bg-img-cont'>
                <div className='search-header-container' id="header">
                    <Link to='/' className='sup-logo'>
                        <img src={logo} alt='National Society of the Sons of Utah Pioneers' />
                    </Link>
                    {hasSearched ?
                        <div className='search-h1'><h1>Book Results</h1></div>
                        :
                        <div className='search-h1'><h1>Book Search</h1></div>
                    }
                    <div className='search-bl-container'>
                        <Link to='/pioneersearch' className='search-back-link'>
                            <p>Go To Pioneer Search</p>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="contact-info-cont search-cInfo">
                <h3>For further information on library books and memorialized pioneers, please contact the SUP National Library:</h3>
                <br />
                <div className="contact-info">
                    <p><b>Phone:</b> 801-484-4441</p>
                    <p><b>Address:</b> 3301 E. Louise Ave Millcreek, UT 84109</p>
                    <p><b>Email:</b> suplibrary01@gmail.com</p>
                </div>
            </div>
            <div className={`search-form-container ${hasSearched ? 'has-searched' : ''}`}>
                <form onSubmit={submitHandler} className={`search-form ${hasSearched ? 'has-searched-bar' : ''}`}>
                    <input type="text" name="search" placeholder='Enter Last Name, First Name' className='search-bar' autoFocus onChange={e => setUserInput(e.target.value)} />
                    <label htmlFor="search" className="search-bar-label">
                        <span className="search-context">(Search the name of the person you wish to know more about)</span>
                    </label>
                </form>
                <div className={`search-btn-container ${hasSearched ? 'has-searched-btn' : ''}`}>
                    <button className='search-btn' onClick={() => bookSearch(userInput)} >Search</button>
                </div>
            </div>
            <div className='mobile-search-btn-cont'>
                <button className='mobile-search-btn' onClick={() => bookSearch(userInput)} >Search</button>
            </div>
            {hasSearched ?
                <div className="count-results-cont">
                    <div><b>Total Results:</b> {searchedBooks.length}</div>
                    <div className='page-info'>
                        <div className='page-count'><b>Page: </b>{currentPage + 1} of {Math.ceil(searchedBooks.length / booksPerPage)}</div>

                        <div className='top-paginate'>
                            <ReactPaginate
                                previousLabel={"prev"}
                                nextLabel={"next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pageCount}
                                marginPagesDisplayed={0}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                                forcePage={currentPage}
                            />
                        </div>
                    </div>
                </div>
                :
                <div></div>
            }
            {mappedBooks}

            {hasSearched ?
                <div className='bottom-paginate'>
                    <ReactPaginate
                        previousLabel={"prev"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={currentPage}
                    />

                    <HashLink to="#header" className='back-to-top'>Back to Top</HashLink>
                </div>
                :
                <div></div>
            }

        </div >
    );
}

export default BookSearch;
