import React from 'react';
import '../Styles/Results.css';

function PioneerResults(props) {
    return (
        <div className='p-results-container'>
            <div className='results-name-cont'>{props.selectedPioneers.pioneer}</div>
            <div className='results-lifespan'>{props.selectedPioneers.lifespan}</div>
            <br />
            <div className='results-text'>
                <b>Key#:</b> {props.selectedPioneers.pointer}
            </div>
            <div className='results-text'>
                <b>Birth Date:</b> {props.selectedPioneers.birthdate}
            </div>
            <div className='results-text'>
                <b>Place of Birth:</b> {props.selectedPioneers.birth_place}
            </div>
            <div className='results-text'>
                <b>Death Date:</b> {props.selectedPioneers.deathdate}
            </div>
            <div className='results-text'>
                <b>Place of Death:</b> {props.selectedPioneers.death_place}
            </div>
        </div>
    );
}

export default PioneerResults;