const initialState = {
    username: '',
    isLoggedIn: false
}

const LOGIN_USER = 'LOGIN_USER'
const LOGOUT_USER = 'LOGOUT_USER'

export function loginUser(username){
    return {
        type: LOGIN_USER,
        payload: username
    }
}

export function logoutUser(){
    return {
        type: LOGOUT_USER,
        payload: initialState
    }
}

export default function(state = initialState, action){
    switch(action.type){
        case LOGIN_USER:
            return{...state, username: action.payload, isLoggedIn: true}
        case LOGOUT_USER:
            return {...state, ...action.payload}
        default:
            return state
    }
}