import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { loginUser, logoutUser } from '../../ducks/userReducer'
import './AdminResults.css'

const AdminResults = (props) => {
    const [isEditing, setIsEditing] = useState(false)

    const [pioneerPointerInput, setPioneerPointerInput] = useState(props.selectedResults.pointer)
    const [pioneerInput, setPioneerInput] = useState(props.selectedResults.pioneer)
    const [lifespanInput, setLifespanInput] = useState(props.selectedResults.lifespan)
    const [birthdateInput, setBirthdateInput] = useState(props.selectedResults.birthdate)
    const [birthplaceInput, setBirthplaceInput] = useState(props.selectedResults.birth_place)
    const [deathdateInput, setDeathdateInput] = useState(props.selectedResults.deathdate)
    const [deathplaceInput, setDeathplaceInput] = useState(props.selectedResults.death_place)

    const [bookPointerInput, setBookPointerInput] = useState(props.selectedResults.pointer)
    const [author, setAuthor] = useState(props.selectedResults.author)
    const [title, setTitle] = useState(props.selectedResults.title)
    const [summary, setSummary] = useState(props.selectedResults.summary)
    const [names, setNames] = useState(props.selectedResults.names)
    const [places, setPlaces] = useState(props.selectedResults.places)

    useEffect(() => {
        axios.get('/api/auth/user').then(res => {
            props.loginUser(res.data)
        }).catch(error => {
            console.log(error);
        })
    }, [])

    const editBooks = () => {
        let body = {
            pointer: bookPointerInput,
            author: author,
            title: title,
            summary: summary,
            names: names,
            places: places
        }

        axios.put(`/api/admin/books/edit/${props.selectedResults.id}`, body).then(res => {
            setIsEditing(false)
        })
    }

    const editPioneers = () => {
        let body = {
            pointer: pioneerPointerInput,
            pioneer: pioneerInput,
            lifespan: lifespanInput,
            birthdate: birthdateInput,
            birth_place: birthplaceInput,
            deathdate: deathdateInput,
            death_place: deathplaceInput
        }

        axios.put(`/api/admin/pioneers/edit/${props.selectedResults.id}`, body).then(res => {
            setIsEditing(false)
        })
    }

    return (
        <div className='admin-results-cont'>
            { props.selectedResults.author ?
                <div className='admin-books-results-cont'>
                    <div className="results-text">
                        <b>Key: </b>{props.selectedResults.pointer}
                    </div>
                    <div className="results-text">
                        <b>Title: </b>{props.selectedResults.title}
                    </div>
                    <div className="results-text">
                        <b>Author: </b>{props.selectedResults.author}
                    </div>
                    <div className="results-text">
                        <b>Names: </b>{props.selectedResults.names}
                    </div>
                    <div className="results-text">
                        <b>Summary: </b>{props.selectedResults.summary}
                    </div>
                    <div className="results-text">
                        <b>Places: </b>{props.selectedResults.places}
                    </div>
                    {isEditing ?
                        <div className='login-cont'>
                            <label htmlFor='pointer' >Key #:</label>
                            <input id='pointer' className='input-bar' defaultValue={props.selectedResults.pointer} onChange={e => setBookPointerInput(e.target.value)} />
                            <label htmlFor='title'>Title:</label>
                            <textarea className='textarea'
                                id='title'
                                rows='10'
                                cols='98'
                                defaultValue={props.selectedResults.title} onChange={e => setTitle(e.target.value)} />
                            <label htmlFor='author'>Author:</label>
                            <input id='author' className='input-bar' defaultValue={props.selectedResults.author} onChange={e => setAuthor(e.target.value)} />
                            <label htmlFor='names'>Names:</label>
                            <textarea className='textarea'
                                id='names'
                                rows='10'
                                cols='98'
                                defaultValue={props.selectedResults.names} onChange={e => setNames(e.target.value)} />
                            <label htmlFor='summary'>Summary:</label>
                            <textarea className='textarea'
                                id='summary'
                                rows='10'
                                cols='98'
                                defaultValue={props.selectedResults.summary} onChange={e => setSummary(e.target.value)} />
                            <label htmlFor='places'>Places:</label>
                            <textarea className='textarea'
                                id='places'
                                rows='10'
                                cols='98'
                                defaultValue={props.selectedResults.places} onChange={e => setPlaces(e.target.value)} />
                            <div className='books-width editing-btns-cont'>
                                <button className='save-btn' onClick={() => editBooks()} >Save</button>
                                <button className='cancel-btn' onClick={() => setIsEditing(false)}>Cancel</button>
                            </div>
                        </div>
                        :
                        <div className='edit-btn-cont'>
                            <button className='edit-btn' onClick={() => setIsEditing(true)}>Edit</button>
                        </div>
                    }
                </div>
                : props.selectedResults.pioneer ?
                    <div>
                        <div className="results-text">
                            <b>Key: </b>{props.selectedResults.pointer}
                        </div>
                        <div className="results-text">
                            <b>Pioneer: </b>{props.selectedResults.pioneer}
                        </div>
                        <div className="results-text">
                            <b>Lifespan: </b>{props.selectedResults.lifespan}
                        </div>
                        <div className="results-text">
                            <b>Birth Date: </b>{props.selectedResults.birthdate}
                        </div>
                        <div className="results-text">
                            <b>Birth Place: </b>{props.selectedResults.birth_place}
                        </div>
                        <div className="results-text">
                            <b>Death Date: </b>{props.selectedResults.deathdate}
                        </div>
                        <div className="results-text">
                            <b>Death Place: </b>{props.selectedResults.death_place}
                        </div>
                        {isEditing ?
                            <div className='login-cont'>
                                <input className='input-bar' defaultValue={props.selectedResults.pointer} onChange={e => setPioneerPointerInput(e.target.value)} />
                                <br />
                                <input className='input-bar' defaultValue={props.selectedResults.pioneer}
                                    onChange={e => setPioneerInput(e.target.value)}
                                />
                                <br />
                                <input className='input-bar' defaultValue={props.selectedResults.lifespan}
                                    onChange={e => setLifespanInput(e.target.value)} />
                                <br />
                                <input className='input-bar' defaultValue={props.selectedResults.birthdate}
                                    onChange={e => setBirthdateInput(e.target.value)} />
                                <br />
                                <input className='input-bar' defaultValue={props.selectedResults.birth_place}
                                    onChange={e => setBirthplaceInput(e.target.value)} />
                                <br />
                                <input className='input-bar' defaultValue={props.selectedResults.deathdate}
                                    onChange={e => setDeathdateInput(e.target.value)} />
                                <br />
                                <input className='input-bar' defaultValue={props.selectedResults.death_place}
                                    onChange={e => setDeathplaceInput(e.target.value)} />
                                <br />
                                <div className='editing-btns-cont'>
                                    <button className='save-btn' onClick={() => editPioneers()}>Save</button>
                                    <button className='cancel-btn' onClick={() => setIsEditing(false)}>Cancel</button>
                                </div>
                            </div>
                            :
                            <div className='edit-btn-cont'>
                                <button className='edit-btn' onClick={() => setIsEditing(true)}>Edit</button>
                            </div>
                        }
                    </div>
                    :
                    <div></div>
            }
        </div>
    )
}

const mapStateToProps = state => state

export default connect(mapStateToProps, { loginUser, logoutUser })(AdminResults)
