import React, { useState, useEffect } from 'react'
import axios from 'axios'
import logo from '../../SUPONLINE.png'
import AdminResults from '../AdminResults/AdminResults'
import { loginUser, logoutUser } from '../../ducks/userReducer'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import './Admin.css';


const Admin = (props) => {

    const [searchResults, setSearchResults] = useState([])
    const [pioneerFilter, setPioneerFilter] = useState(false)
    const [bookFilter, setBookFilter] = useState(false)
    const [userInput, setUserInput] = useState('')
    const [hasSearched, setHasSearched] = useState(false)

    useEffect(() => {
        axios.get('/api/auth/user').then(res => {
            props.loginUser(res.data)
        }).catch(error => {
            console.log(error);
        })
    }, [])

    const searchData = () => {
        if (pioneerFilter === true && bookFilter === false) {
            axios.get(`/api/admin/pioneers?pointer=${userInput}`).then(res => {
                setHasSearched(true)
                setSearchResults(res.data)
                setHasSearched(true)
                handleReset()
            })
        } else if (bookFilter === true && pioneerFilter === false) {
            axios.get(`/api/admin/books?pointer=${userInput}`).then(res => {
                setSearchResults(res.data)
                setHasSearched(true)
                handleReset()
            })
        } else if (bookFilter === true && pioneerFilter === true) {
            alert('Please Select One Option')
        } else {
            alert('Please Check The Box That Matches What You Are Searching For')
        }
    }

    const submitHandler = event => {
        event.preventDefault();
        searchData();
    }

    const handleLogout = () => {
        axios.delete('/api/auth/logout').then(() => {
            props.logoutUser()
            props.history.push('/login')
        })
    }

    const handleReset = () => {
        Array.from(document.querySelectorAll("input")).forEach(
            input => (input.value = "")
        )
        setUserInput('')
    }

    const mappedResults = searchResults.map((selectedResults) => {
        return (
            <AdminResults
                key={selectedResults.id}
                selectedResults={selectedResults}
            />
        )
    })

    return (
        <div> {props.isLoggedIn ?
            <div className='search-container'>
                <div className="search-header-bg-img-cont">
                    <div className='search-header-container admin-head-cont'>
                        <div className='sup-logo admin-logo'>
                            <img src={logo} alt='National Society of the Sons of Utah Pioneers' />
                        </div>

                        <div className='search-h1'><h1>Welcome {props.username}</h1></div>

                        <div className='logout-cont'>
                            <button className='logout-btn' onClick={() => handleLogout()} >Logout</button>
                        </div>
                    </div>
                </div>
                <div className="row1-content-cont">
                    <div className='filter-cont'>
                        <p>Please Select One Option Below:</p>
                        <div className="chkbox-cont">
                            <input className='chkbox-option' type='checkbox' name='pioneer-chkbox' onChange={(e) => {
                                let checked = e.target.checked
                                setPioneerFilter(true)
                                if (pioneerFilter === true) {
                                    setPioneerFilter(false)
                                }
                            }} ></input>
                            <label htmlFor='pioneer-chkbox'>Search Pioneers</label>
                        </div>
                        <div className="chkbox-cont">
                            <input className='chkbox-option' type='checkbox' name='books-chkbox' onChange={(e) => {
                                let checked = e.target.checked
                                setBookFilter(true)
                                if (bookFilter === true) {
                                    setBookFilter(false)
                                }
                            }}></input>
                            <label htmlFor='books-chkbox'>Search Books</label>
                        </div>
                    </div>
                    <div className="add-entry-btn-cont">
                        <Link to='/admin/add' >
                            <button className='admin-btn'>Add New Entries </button>
                        </Link>
                    </div>
                </div>
                <div className={`search-form-container ${hasSearched ? 'has-searched' : ''}`}>
                    <form onSubmit={submitHandler} className={`search-form ${hasSearched ? 'has-searched-bar' : ''}`}>
                        <input type="text" name="search" placeholder="Search With a Key Number After Checking the Corresponding Box" className='search-bar' autoFocus
                            onChange={e => setUserInput(e.target.value)} />
                    </form>

                    <div className={`search-btn-container ${hasSearched ? 'has-searched-btn' : ''}`}>
                        <button className='search-btn' onClick={() => searchData(userInput)} >Search</button>
                    </div>
                </div>
                <div className='mobile-btn-cont'>
                    <Link to='/admin/add' >
                        <button className='mobile-entry-btn'>Add New Entries </button>
                    </Link>
                    <button className='mobile-adminSearch-btn' onClick={() => searchData(userInput)} >Search</button>
                </div>
                {searchResults.length >= 1 && hasSearched ?
                    <div className='mapped-results'>
                        {mappedResults}
                    </div>
                    :
                    searchResults.length < 1 && hasSearched ?
                        <div className='no-results-cont'>No Results Found</div>
                        :
                        <div></div>
                }

            </div>
            :
            <div>Please Log In To Continue</div>
        }
        </div>
    )
}

const mapStateToProps = state => state

export default connect(mapStateToProps, { loginUser, logoutUser })(Admin)