import React from 'react';
import './App.css';
import routes from './routes'

function App() {
  return (
    <div className="app">
    
      <div className='full-page-section'>
        {routes}
      </div>

    </div>
  );
}

export default App;
